import { useEffect, useState } from "react";
import Item from "./Item";
import { getEducationCard } from "services/educationCardService";
import { Stack, Typography } from "@mui/material";
import { ReferenceKey } from "@strmediaochitab/optima-component-library";
import { useEducationCardList } from "hooks/useEducationCard";
import { EducationCardSkeleton } from "utils/helpers/LoadingSkeletons";
import TheoryDialog from "layout/Components/LearningActivityDialog";
import { EducationCardProgress } from "types/educationCard";
import ItemTestTheoretical from "./ItemTestTheoretical";

type ListProps = {
  eventId: string;
  referenceKey: ReferenceKey;
  userIds?: string[];
  educationCardIdentifier: string;
  setProgress: (progress: EducationCardProgress) => void;
};

const List = ({ eventId, referenceKey, userIds, educationCardIdentifier, setProgress }: ListProps) => {
  const { listIds, setList } = useEducationCardList(educationCardIdentifier);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      if (!userIds) return;
      setIsLoading(true);

      const educationCard = await getEducationCard(eventId, referenceKey, userIds, educationCardIdentifier);
      setList(educationCard.activities);
      setProgress(educationCard.progress);
      setIsLoading(false);
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationCardIdentifier]);

  if (isLoading) return <EducationCardSkeleton />;

  return (
    <>
      <Typography variant="subtitle1" mb={1.5} mt={3}>
        Utbildningsinnehåll
      </Typography>
      <Stack spacing={1.5}>
        {listIds.map((id) => (
          <Item key={id} id={id} setProgress={setProgress} />
        ))}

        <ItemTestTheoretical />
      </Stack>

      <TheoryDialog />
    </>
  );
};

export default List;
