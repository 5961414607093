import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useFetch } from "hooks/useFetch";
import { useGlobalSnackbarMessage } from "hooks/useSnackbarMessage";
import { FormInputPassword } from "layout/Components/Inputs/formInputPassword";
import { useForm } from "react-hook-form";
import { useAppIntl } from "services/useAppIntl";
import { testId } from "tests/testIdStrings";
import { Icon, iconKey } from "theme/icons";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_URL_CS } from "utils/constants";
import { LoadingIndicator } from "utils/helpers/LoadingIndicator";
import { validationSchemaActorUpdatePassword } from "services/validationSchemaUser";
import { useRecoilValue } from "recoil";
import { userStateAccount } from "state/userState";

type FormProps = {
  password: string;
};
type DeleteProps = { id: string; name: string; identityNumber: string };

const Password = ({ id, name, identityNumber }: DeleteProps) => {
  const intl = useAppIntl();
  const { groupId } = useParams();
  const [open, setOpen] = useState(false);
  const { put, json, isLoadingLocal, error } = useFetch(undefined, true);
  const { setSnackbarValues } = useGlobalSnackbarMessage();
  const userAccount = useRecoilValue(userStateAccount);

  if (!userAccount) throw new Error("No user account found");
  if (!groupId) throw new Error("groupId is missing");

  const { control, handleSubmit, formState, reset } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: { password: "" },
    resolver: yupResolver(validationSchemaActorUpdatePassword),
  });

  useEffect(() => {
    if (error) setSnackbarValues({ message: error.title, type: "error" });

    if (json) {
      setOpen(false);
      setSnackbarValues({ message: intl.formatMessage({ id: "common.save.message" }), type: "success" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, json]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) reset();
  }, [formState, reset]);

  const onSubmit = (data: FormProps) => {
    put(`${API_URL_CS}${userAccount.customerId}/members/${id}/password`, {
      password: data.password.length > 0 ? data.password : null,
    });
    setOpen(false);
  };

  const handleSave = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <IconButton color="info" onClick={() => setOpen(true)}>
        <Icon name={iconKey} />
      </IconButton>
      {open && (
        <DialogMessage
          title={intl.formatMessage({ id: "profile.label.password" })}
          open={true}
          close={() => setOpen(false)}
          primaryButtonText="common.save"
          primaryButtonLoader={isLoadingLocal ? true : false}
          primaryButtonDisabled={!formState.isValid || isLoadingLocal === true}
          dataTestIdString={`${testId.button.dialogMessage}-save`}
          primaryButtonAction={handleSave}
        >
          <FormattedTypography variant="body1" id="student.dialog.password.body" values={{ name: name }} mb={1} />
          <FormInputPassword control={control} required={false} />
          <CurrentPassword customerId={userAccount.customerId} identityNumber={identityNumber} groupId={groupId} />
        </DialogMessage>
      )}
    </>
  );
};

export default Password;

const CurrentPassword = ({
  customerId,
  identityNumber,
  groupId,
}: {
  customerId: string;
  identityNumber: string;
  groupId: string;
}) => {
  const { get, json, isLoadingLocal, error } = useFetch<[{ userName: string; password: string }]>(undefined, true);
  const { setSnackbarValues } = useGlobalSnackbarMessage();

  useEffect(() => {
    if (error) setSnackbarValues({ message: error.title, type: "error" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleShow = () => {
    get(`${API_URL_CS}${customerId}/learningevents/${groupId}/members/passwords?identityNumber=${identityNumber}`);
  };

  return (
    <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center" mt={1}>
      <Button color="info" onClick={handleShow}>
        <FormattedTypography variant="caption" id="student.button.show-password" />
      </Button>
      <Typography variant="body1">
        {isLoadingLocal ? <LoadingIndicator size={20} /> : json ? json[0].password : ""}
      </Typography>
    </Stack>
  );
};
