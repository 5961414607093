import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SvgIcon, SvgIconTypeMap, SxProps, Theme } from "@mui/material";

// Regular style is default
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

// Bottom menu icons
export const iconHome = regular("house");
export const iconHeart = regular("heart");
export const iconHeartSolid = solid("heart");
export const iconTheory = regular("book-open");
export const iconTest = regular("medal");
export const iconProgress = regular("chart-line-up");
export const iconMoreCircle = regular("circle-ellipsis");
export const iconHomeActive = solid("house");
export const iconTheoryActive = solid("book-open");
export const iconTestActive = solid("medal");
export const iconProgressActive = solid("chart-line-up");
export const iconMoreActive = solid("circle-ellipsis");

// Vehicles
export const iconMotorCycle = solid("motorcycle");
export const iconMoped = solid("moped");
export const iconCar = solid("car-side");
export const iconTruck = solid("truck");
export const iconBus = solid("bus");
export const iconTaxi = solid("taxi");
export const iconTractor = solid("tractor");

export const iconBook = regular("book-blank");
export const iconBookSolid = solid("book");
export const iconArrowLeft = solid("arrow-left");
export const iconArrowLeftRegular = regular("arrow-left");
export const iconArrowRight = solid("arrow-right");
export const iconArrowRightRegular = regular("arrow-right");
export const iconArrowCircleUp = regular("arrow-circle-up");
export const iconArrowCircleLeft = regular("arrow-circle-left");
export const iconArrowCircleRight = regular("arrow-circle-right");
export const iconArrowRotateRight = regular("arrow-rotate-right");
export const iconList = solid("list");
export const iconMagnifyingGlass = regular("magnifying-glass");
export const iconListCheck = regular("list-check");
export const iconListDots = regular("list-dots");
export const iconCheck = regular("check");
export const iconCheckSolid = solid("check");
export const iconClose = regular("times");
export const iconCheckboxEmpty = regular("square");
export const iconCheckbox = regular("square-check");
export const iconCheckboxSolid = solid("square-check");
export const iconChevronRight = regular("chevron-right");
export const iconChevronUp = regular("chevron-up");
export const iconChevronDown = regular("chevron-down");
export const iconChevronLeft = regular("chevron-left");
export const iconChevronLeftSolid = solid("chevron-left");
export const iconChevronRightSolid = solid("chevron-right");
export const iconBookmark = regular("bookmark");
export const iconBookmarkSolid = solid("bookmark");
export const iconUser = regular("user");
export const iconUserSolid = solid("user");
export const iconUserCheck = regular("user-check");
export const iconStudentUser = solid("user-graduate");
export const iconUserPen = solid("user-pen");
export const iconMessage = regular("comment-alt");
export const iconCompass = solid("compass");
export const iconLanguage = regular("globe");
export const iconSolidGlobe = solid("globe");
export const iconLanguageSelected = regular("flag");
export const iconSettings = regular("gear");
export const iconSettingsSolid = solid("gear");
export const iconLogout = regular("arrow-right-from-bracket");
export const arrowUpFromBracket = solid("arrow-up-from-bracket");
export const iconPhone = regular("phone");
export const iconPhoneSolid = solid("phone");
export const iconEnvelope = regular("envelope");
export const iconSolidEnvelope = solid("envelope");
export const iconExternalLink = regular("arrow-up-right-from-square");
export const iconShowPassword = solid("eye");
export const iconHidePassword = solid("eye-slash");

export const iconTrashcan = regular("trash-can");
export const iconTrashcanSolid = solid("trash-can");
export const iconNotes = regular("pen-to-square");
export const iconXmark = regular("xmark");
export const iconXmarkLarge = regular("xmark-large");
export const iconCircleXmarkSolid = solid("circle-xmark");
export const iconCircleXmark = regular("circle-xmark");
export const iconEducator = solid("person-chalkboard");
export const iconStarFilled = solid("star");
export const iconStar = regular("star");
export const iconSquareQuestion = regular("square-question");
export const iconSquareQuestionActive = solid("square-question");
export const iconTriangle = regular("triangle");
export const iconTriangleSolid = solid("triangle");
export const iconTriangleExclamation = regular("triangle-exclamation");
export const iconQuestion = regular("question");
export const iconMessageQuestion = regular("message-question");
export const iconSeedling = solid("seedling");
export const iconCity = solid("city");
export const iconRoad = solid("road");
export const iconRoute = solid("route");
export const iconArrowTrendUp = regular("arrow-trend-up");
export const iconPuzzle = regular("puzzle-piece");
export const iconPuzzleActive = solid("puzzle-piece");
export const iconCommentsQuestion = regular("comments-question-check");
export const iconCommentsQuestionActive = solid("comments-question-check");
export const iconCircle = regular("circle");
export const iconCircleDot = regular("circle-dot");
export const iconCircleCheck = solid("circle-check");
export const iconCircleInfo = regular("circle-info");
export const iconCircleInfoSolid = solid("circle-info");
export const iconCircleExclamation = solid("circle-exclamation");
export const iconVolume = regular("volume");
export const iconFile = regular("file");
export const iconPen = regular("pencil");
export const iconCaretDown = solid("caret-down");
export const iconMoneyCheckPen = regular("money-check-pen");
export const iconGrid = solid("grid");
export const iconMap = solid("map-location-dot");
export const iconLocation = solid("location-dot");
export const iconClockFilled = solid("clock");
export const iconClock = regular("clock");
export const iconHistory = solid("clock-rotate-left");
export const iconExclamation = solid("exclamation");
export const iconCommentExclamation = solid("comment-exclamation");
export const iconAward = regular("award");
export const iconLock = solid("lock");
export const iconLockOpen = solid("lock-open");
export const iconAdd = solid("add");
export const iconChartColumnSolid = solid("chart-column");
export const iconGlasses = regular("glasses-round");
export const iconMinus = solid("minus");
export const iconPlus = solid("plus");
export const iconCaravan = solid("caravan-simple");
export const iconMore = regular("ellipsis");
export const removeAccount = solid("user-xmark");
export const iconMobile = solid("mobile");
export const iconSearch = regular("magnifying-glass");
export const iconLocationArrow = solid("location-arrow");
export const iconShuffle = regular("shuffle");
export const iconPlay = regular("play");
export const iconLightBulb = solid("lightbulb-on");
export const iconLightBulbRegular = regular("lightbulb-on");
export const iconComment = regular("comment-lines");
export const iconCommentSolid = solid("comment-lines");
export const iconCircleSolid = solid("circle");
export const iconMenu = solid("bars");
export const iconCommentAdd = regular("comment-plus");
export const iconCommentShare = solid("comment-arrow-up-right");
export const iconUserGroup = regular("user-group");
export const iconArrowsRotate = regular("arrows-rotate");
export const iconKey = regular("key");
export const iconNoComment = regular("comment-slash");
export const iconSliders = regular("sliders");
export const iconHourglass = regular("hourglass-end");
export const iconBan = solid("ban");

export const iconFMEducator = regular("person-military-rifle");
export const iconFMStudent = regular("person-rifle");
export const iconTheme = regular("palette");

// Note: Default size (medium) is rendered as 24px
// Sizes according to Optima UX should be as follow:
// small = 20px
// medium = 24px
// large = 30px
// xlarge = 40px
type SvgSizeProp = SvgIconTypeMap["props"]["fontSize"] | "xlarge";

// Re-export for internal app use
export type { IconProp, SvgSizeProp as SizeProp };

type Props = {
  name: IconProp;
  size?: SvgSizeProp;
  color?: SvgIconTypeMap["props"]["color"];
  fontSize?: string;
  htmlColor?: string;
  sx?: SxProps<Theme>;
};

/**
 * Wrapper component for generating optima app icons
 */
export const Icon = ({ name, size, color, htmlColor, fontSize, sx = [] }: Props) => {
  // Handle optima sizes
  if (size === "large") {
    fontSize = "1.875rem";
    size = undefined;
  }
  if (size === "xlarge") {
    fontSize = "2.5rem";
    size = undefined;
  }

  return (
    <SvgIcon
      sx={[{ padding: "2px" }, ...(Array.isArray(sx) ? sx : [sx]), fontSize ? { fontSize: fontSize } : undefined]}
      fontSize={size}
      color={color}
      htmlColor={htmlColor}
    >
      <FontAwesomeIcon icon={name} />
    </SvgIcon>
  );
};
