import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormSwitch from "utils/helpers/FormSwitch";
import ListHeader from "layout/Components/ListHeader";
import { ContentLoader } from "utils/helpers/LoadingIndicator";
import { educationCardPropsState } from "state/educationCardState";
import { studentsSelector } from "state/educatorState";
import { LearningEventStudent } from "types/learningEvent";
import Status from "../Status";
import { LoadingStatus } from "types/request";
import { StudentsProps } from ".";
import EducationCardLink from "./EducationCardLink";

type ListProps = {
  edit: boolean;
  item: StudentsProps["item"];
  onApprove: StudentsProps["onApprove"];
  onApproveAll: StudentsProps["onApproveAll"];
};

function List({ edit, item, onApprove, onApproveAll }: ListProps) {
  const [status, setStatus] = useState<LoadingStatus>("none");
  const state = useRecoilValue(educationCardPropsState);
  const { groupId } = useParams();
  const groupStudents = useRecoilValue(studentsSelector(groupId));

  const students = groupStudents.map((student) => {
    const itemStudent = item.studentStatus?.get(student.id);
    return {
      ...student,
      status: itemStudent?.status || "NotStarted",
      approved: itemStudent?.approved || false,
    };
  }) as LearningEventStudent[];

  async function handleApprove(checked: boolean, student: LearningEventStudent) {
    if (!state) throw new Error("No education card state found");

    console.log("approving student", checked, student, item.title);
    setStatus("pending");

    // Approve all chapters
    if (item.theoryItems) {
      if (onApproveAll) await onApproveAll(checked, student.id);
    } else {
      // Approve single chapter
      if (onApprove) await onApprove(item, "theoretical", checked, student.id);
    }

    setStatus("none");
  }

  return (
    <Box mx={-2} my={-3}>
      <ContentLoader isLoading={status === "pending"} loadingText="Sparar..." />
      <TableContainer sx={{ pb: 1.5 }}>
        <Table size="small" aria-label="Table of students">
          <TableHead>
            <TableRow>
              <TableCell>
                <ListHeader>Förare</ListHeader>
              </TableCell>
              <TableCell align="center" width="100">
                <ListHeader>Status</ListHeader>
              </TableCell>
              <TableCell align="center" width="100" sx={{ pl: 3 }}>
                <ListHeader>Klar</ListHeader>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((row) => (
              <ListRow
                key={row.id}
                student={row}
                edit={edit}
                approve={<FormSwitch checked={row.approved} onChange={(checked) => handleApprove(checked, row)} />}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default List;

const ListRow = ({
  student,
  edit,
  approve,
}: {
  student: LearningEventStudent;
  edit: ListProps["edit"];
  approve: ReactNode;
}) => {
  const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;

  return (
    <>
      <TableRow sx={{ height: "2.5rem", "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <EducationCardLink studentId={student.id}>
            <span>{name}</span>
          </EducationCardLink>
        </TableCell>
        <TableCell align="center">
          <Status status={student.status} />
        </TableCell>
        <TableCell align="center">{edit ? approve : "Nej"}</TableCell>
      </TableRow>
    </>
  );
};
