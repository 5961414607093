import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { Icon, iconArrowRightRegular } from "theme/icons";

// TODO: Ska den öppnas i en dialog?? Går nog inte i nuläget då vi bara kan ha ett utbildningskort öppet i taget
const EducationCardLink = ({ children, studentId }: { studentId: string } & PropsWithChildren) => {
  // const [open, setOpen] = useState(false);

  return (
    <>
      <Link
        display="flex"
        alignItems="center"
        component={RouterLink}
        to={`students/${studentId}`}
        sx={{ textDecoration: "none" }}
      >
        {children}
        <Icon name={iconArrowRightRegular} color="info" sx={{ ml: 1 }} />
      </Link>

      {/* {open && (
        <StudentEducationcardDialog
          title="Elevens utbildningskort"
          open={true}
          close={() => setOpen(false)}
          studentId={studentId}
        ></StudentEducationcardDialog>
      )} */}
    </>
  );
};

export default EducationCardLink;
