import { Suspense, useState } from "react";
import { Button } from "@mui/material";
import { Icon, iconUserGroup } from "theme/icons";
import { EducationCardActivity, EducationCardActivityTest } from "types/educationCard";
import { ContentLoader } from "utils/helpers/LoadingIndicator";
import List from "./List";
import ApproveDialog from "../ApproveDialog";
import ListTest from "./ListTest";

const styleButton = {
  height: "24px",
  minWidth: "auto",
  borderRadius: "100px",
};

export type StudentsProps = {
  item: EducationCardActivity | EducationCardActivityTest;
  onApprove?: (
    item: EducationCardActivity,
    type: "theoretical" | "practical",
    checked: boolean,
    userId: string
  ) => Promise<void>;
  onApproveAll?: (checked: boolean, userId?: string) => Promise<void>;
  // onUnlock?: (checked: boolean, userId: string) => Promise<void>;
};

const Students = ({ item, onApprove, onApproveAll }: StudentsProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="outlined" size="small" sx={styleButton} onClick={() => setOpen(true)}>
        <Icon name={iconUserGroup} size="small" color="info" />
      </Button>

      {open && (
        <ApproveDialog title={item.title} open={true} close={() => setOpen(false)}>
          <Suspense fallback={<ContentLoader isLoading={true} />}>
            {/* Render special list if the item is a final test item */}
            {"finalTestUnlocked" in item ? (
              <ListTest edit={true} item={item} />
            ) : (
              <List edit={true} item={item} onApprove={onApprove} onApproveAll={onApproveAll} />
            )}
          </Suspense>
        </ApproveDialog>
      )}
    </>
  );
};

export default Students;
