// import { Control, Controller } from "react-hook-form";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon, iconCheckSolid, iconClose, iconLock, iconLockOpen } from "theme/icons";

const StyledSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "&.checked": {
      backgroundColor: theme.palette.success.main,
    },
    "&.unchecked": {
      backgroundColor: "white",
      color: theme.palette.text.disabled,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  "& .MuiSwitch-track": {},
}));

type FormSwitchProps = {
  checked: boolean;
  label?: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  disabled?: boolean;
  type?: "check" | "lock";
  onChange?: (checked: boolean) => void;
};

/**
 * A custom switch component that renders a styled switch or a default switch based on the `type` prop.
 * It also supports custom labels, label placement, and disabled state.
 *
 * @param {boolean} checked - Determines whether the switch is checked.
 * @param {boolean} [disabled] - If true, the switch will be disabled.
 * @param {string} [label] - The label to display next to the switch.
 * @param {boolean} [noLabel] - If true, no label will be displayed.
 * @param {string} [labelPlacement] - The position of the label relative to the switch. Defaults to "start".
 * @param {string} [type] - The type of switch to render. If provided, a styled switch will be used.
 * @param {Function} [onChange] - Callback function to handle change events.
 *
 * @returns {JSX.Element} The rendered FormSwitch component, and with a custom icon if type is supplied.
 */
const FormSwitch = (props: FormSwitchProps) => {
  function handleChange(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (props.onChange) props.onChange(checked);
  }

  // Use different switch component if type is set
  const SwitchComponent = props.type ? (
    <StyledSwitch
      onChange={handleChange}
      color={"success"}
      checked={props.checked}
      checkedIcon={<SwitchThumb checked={true} type={props.type} />}
      icon={<SwitchThumb checked={false} type={props.type} />}
    />
  ) : (
    <Switch onChange={handleChange} color={"success"} checked={props.checked} />
  );

  return (
    <FormControlLabel
      control={SwitchComponent}
      label={props.label}
      labelPlacement={props.labelPlacement ?? "start"}
      slotProps={{ typography: { fontSize: "12px" } }}
      disabled={props.disabled}
      sx={{ maxHeight: "1.5rem", margin: "0" }}
    />
  );
};

export default FormSwitch;

function SwitchThumb(props: { checked: FormSwitchProps["checked"]; type?: FormSwitchProps["type"] }) {
  let iconChecked = iconCheckSolid;
  let iconUnchecked = iconClose;
  let fontSize = "18px";

  if (props.type === "lock") {
    iconChecked = iconLockOpen;
    iconUnchecked = iconLock;
    fontSize = "14px";
  }

  return (
    <Box className={`MuiSwitch-thumb ${props.checked ? "checked" : "unchecked"}`}>
      {props.checked ? (
        <Icon name={iconChecked} fontSize={fontSize} />
      ) : (
        <Icon name={iconUnchecked} fontSize={fontSize} />
      )}
    </Box>
  );
}
