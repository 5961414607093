import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ListHeader from "layout/Components/ListHeader";
import { ContentLoader } from "utils/helpers/LoadingIndicator";
import { studentsSelector } from "state/educatorState";
import { LearningEventStudent } from "types/learningEvent";
import Status from "../Status";
import { LoadingStatus } from "types/request";
import { StudentsProps } from ".";
import EducationCardLink from "./EducationCardLink";
import useFinalTestInfo from "../useFinalTestForEducator";
import FormSwitch from "utils/helpers/FormSwitch";

type ListProps = {
  edit: boolean;
  item: StudentsProps["item"];
};

function ListTest({ edit, item }: ListProps) {
  const [status, setStatus] = useState<LoadingStatus>("none");
  const { groupId } = useParams();
  const groupStudents = useRecoilValue(studentsSelector(groupId));

  const students = groupStudents.map((student) => {
    const itemStudent = item.studentStatus?.get(student.id);
    return {
      ...student,
      status: itemStudent?.status || "NotStarted",
      approved: itemStudent?.approved || false,
      // finalTestUnlocked: itemStudent?.finalTestUnlocked || false, Ska denna vara här?
    };
  }) as LearningEventStudent[];

  console.log("ListTest rendered", item);

  return (
    <Box mx={-2} my={-3}>
      <ContentLoader isLoading={status === "pending"} loadingText="Sparar..." />
      <TableContainer sx={{ pb: 1.5 }}>
        <Table size="small" aria-label="Table of students">
          <TableHead>
            <TableRow>
              <TableCell>
                <ListHeader>Förare</ListHeader>
              </TableCell>
              <TableCell align="center" width="100">
                <ListHeader>Provresultat</ListHeader>
              </TableCell>
              <TableCell align="center" width="100" sx={{ pl: 3 }}>
                <ListHeader>Godkänd</ListHeader>
              </TableCell>

              <TableCell align="center" width="100" sx={{ pl: 3 }}>
                <ListHeader>Upplåst</ListHeader>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((row) => (
              <ListRow key={row.id} student={row} edit={edit} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ListTest;

const ListRow = ({ student, edit }: { student: LearningEventStudent; edit: ListProps["edit"] }) => {
  const { finalTestUnlocked, finalTestApproved, finalTestSuccess, handleApprove, handleUnlock } = useFinalTestInfo([
    student.id,
  ]);
  const name = `${student.extensions?.firstName} ${student.extensions?.lastName}`;

  // console.log("user test status for", student, testId, finalTestUnlocked, finalTestApproved, finalTestSuccess);

  return (
    <>
      <TableRow sx={{ height: "2.5rem", "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          <EducationCardLink studentId={student.id}>
            <span>{name}</span>
          </EducationCardLink>
        </TableCell>
        <TableCell align="center">
          {/* TODO: Förbättra hantering av success status */}
          <Status status={finalTestSuccess ? "Success" : "Fail"} />
        </TableCell>
        <TableCell align="center">
          {edit ? (
            <FormSwitch
              checked={finalTestApproved}
              label="Godkänd"
              onChange={(checked) => handleApprove(checked)}
              type="check"
            />
          ) : (
            "Nej"
          )}
        </TableCell>
        <TableCell align="center">
          {
            <FormSwitch
              checked={finalTestUnlocked}
              label="Upplåst"
              onChange={(checked) => handleUnlock(checked)}
              type="lock"
            />
          }
        </TableCell>
      </TableRow>
    </>
  );
};
