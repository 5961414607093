import { ItemAssessment, LRSProviderState } from "@strmediaochitab/optima-component-library";
import { useAssessment } from "hooks/useAssessment";
import { useTestSettings } from "hooks/useTestSettings";
import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { XapiKey, getLrsState, saveLrsState } from "services/lrsService";
import { educationStateReferenceKey } from "state/educationState";
import { educationContentTypeState } from "state/learningStructureState";
import { ReferenceKey } from "types/cds";
import { v4 as uuidv4 } from "uuid";

// Helper function to save the LRS state for the correct type of action
function saveLrsStates(
  type: "unlock" | "approve",
  checked: boolean,
  userIds: string[],
  educationKey: ReferenceKey,
  stateId: string,
  assessment: ItemAssessment
) {
  return Promise.all(
    userIds.map(async (userId) => {
      const xapiKey: XapiKey = {
        userId: userId,
        contentId: educationKey.contentId,
        versionId: educationKey.versionId!,
        stateId: stateId,
      };

      let newState: LRSProviderState = {
        id: uuidv4(),
        user: { id: userId },
        referenceKey: assessment.referenceKey,
      };

      if (type === "unlock") newState.finalTestLocked = !checked;
      if (type === "approve") newState.finalTestApproved = checked;

      console.log("switching test ", type, "to", checked, "for user", userId, newState);

      await saveLrsState(xapiKey, newState);
    })
  );
}

const useFinalTestForEducator = (userIds: string[]) => {
  const educationKey = useRecoilValue(educationStateReferenceKey);
  if (!educationKey) throw new Error("No education state reference key found");

  const { finalTest } = useTestSettings();
  const stateId = useRecoilValue(educationContentTypeState).get(finalTest.questionType)!;
  const { assessment } = useAssessment({
    type: finalTest.questionType,
    key: educationKey,
  });

  const [finalTestApproved, setFinalTestApproved] = useState<boolean>(false);
  const [finalTestUnlocked, setFinalTestUnlocked] = useState<boolean>(false);
  const [finalTestSuccess, setFinalTestSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!assessment) return;

    async function getAllStates(
      userIds: string[],
      educationKey: ReferenceKey,
      stateId: string,
      assessment: ItemAssessment
    ) {
      let approved: boolean[] = [];
      let unlocked: boolean[] = [];
      let success: boolean[] = [];

      await Promise.all(
        userIds.map(async (userId) => {
          const xapiKey: XapiKey = {
            userId: userId,
            contentId: educationKey.contentId,
            versionId: educationKey.versionId!,
            stateId: stateId,
          };

          return await getLrsState(xapiKey);
        })
      )
        .then((responses) => {
          responses.forEach((lrsState) => {
            let assessmentState = lrsState.get(assessment.referenceKey.versionId);

            console.log("assessmentState", assessmentState);

            approved.push(assessmentState?.finalTestApproved === true);
            unlocked.push(assessmentState?.finalTestLocked === false);
            success.push(assessmentState?.result?.success === true);
          });
        })
        .finally(() => {
          setFinalTestApproved(approved.every((u) => u));
          setFinalTestUnlocked(unlocked.every((u) => u));
          setFinalTestSuccess(success.every((u) => u));
        });
    }

    getAllStates(userIds, educationKey, stateId, assessment);
  }, [userIds, assessment, educationKey, stateId]);

  async function handleApprove(checked: boolean) {
    if (!assessment || !educationKey) throw new Error("No education state reference key OR assessment found");

    // if (onBeforeChange) onBeforeChange();

    await Promise.all(await saveLrsStates("approve", checked, userIds, educationKey, stateId, assessment))
      .then(() => {
        setFinalTestApproved(checked);
      })
      .finally(() => {
        // if (onAfterChange) onAfterChange();
      });
  }

  async function handleUnlock(checked: boolean) {
    if (!assessment || !educationKey) throw new Error("No education state reference key OR assessment found");

    // if (onBeforeChange) onBeforeChange();

    await Promise.all(await saveLrsStates("unlock", checked, userIds, educationKey, stateId, assessment))
      .then(() => {
        setFinalTestUnlocked(checked);
      })
      .finally(() => {
        // if (onAfterChange) onAfterChange();
      });
  }

  return {
    finalTestApproved,
    finalTestUnlocked,
    finalTestSuccess,
    handleApprove,
    handleUnlock,
  };
};

export default useFinalTestForEducator;
